@use './variables';

@mixin media {
  @media (min-width: 900px), (orientation: landscape) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: 600px) {
    @content;
  }
}

@mixin titleTypography {
  color: variables.$keune-black;
  font-family: variables.$font-title;
  font-weight: bold;
  letter-spacing: -0.1rem;
}

@mixin headlineTypography {
  color: variables.$keune-black;
  font-family: variables.$font-headline;
  font-weight: normal;
  letter-spacing: -0.1rem;
}

@mixin roundButton {
  background: #ffffff;
  border-radius: 50%;
  width: 3.6rem;
  height: 3.6rem;
  font-size: 1.6rem;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
}
@mixin standardButton {
  line-height: 1.4rem;
  border-radius: variables.$button-border-radius;
  margin-top: 1.4rem;
  padding: 1.3rem 2rem;
  font-size: 1.4rem;
  font-family: variables.$font-headline;
}
@mixin bodyTypography {
  color: variables.$keune-black;
  font-family: variables.$font-body;
}

@mixin freigthTypography {
  font-family: 'Freight Big';
  letter-spacing: 0;
}

@mixin listStyling {
  @include bodyTypography;
  font-size: 1.6rem;
  margin-left: 1.6rem;
  line-height: 150%;
  letter-spacing: -0.05rem;
  @include media {
    font-size: 1.8rem;
    margin-left: 2.4rem;
  }
}

// Mixins for product page/card styling
@mixin productTag {
  @include headlineTypography;
  width: 5.3rem;
  height: 1.6rem;
  padding: 0.4rem 0.6rem;
  font-size: 0.8rem;
  background: #cac399;
  color: #ffffff;
  text-align: center;
  white-space: nowrap;
  margin-bottom: 2rem;
  letter-spacing: 0rem !important;
  @include media {
    font-size: 1rem;
    width: 8rem;
    height: 2.2rem;
    padding: 0.6rem 0.8rem;
  }
}
@mixin productTagsRow {
  display: flex;
  position: relative;
  justify-content: flex-start;

  :not(:first-child) {
    margin-left: 1rem;
  }
}

@mixin large {
  @media (min-width: 1441px) {
    @content;
  }
}

@mixin loaderAnim {
  @keyframes spinning {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate((360deg));
    }
  }
  animation: spinning 1.5s linear infinite;
}

@mixin reviewStarsSizes {
  width: calc(variables.$reviewStarsHeightMobile * 5) !important;
  height: variables.$reviewStarsHeightMobile !important;
  @include media {
    width: calc(variables.$reviewStarsHeight * 5) !important;
    height: variables.$reviewStarsHeight !important;
  }
}

@mixin reviewBackgroundSizes {
  background-size: variables.$reviewStarsHeightMobile !important;
  @include media {
    background-size: variables.$reviewStarsHeight !important;
  }
}
