// :root {
//   --column: 1.5625rem;
//   --white: white;

//   @include mixins.media {
//     --column: 7.2rem;
//   }
// }

$font-headline: 'Acumin Pro';
$font-title: 'Acumin Pro';
$font-body: 'Acumin Pro';
$font-scripty: 'Benton';
$document-margin: 6rem;
$keune-black: #3c3c3b;
$keune-white: #f9f9f9;
$keune-gold: #cac399;
$keune-dark-red: #dc5a40;
$keune-red: #ff6969;
$keune-purple: #8b84d7;
$success-green: rgb(61, 158, 61);
$keune-background: #fffefc;
$keune-white-transparent: #f9f9f900;
$keune-white-50-percent: rgba(255, 255, 255, 0.5);
// This is equal to keune-black color, but with 50% opacity.
$keune-black-50-percent: rgba(60, 60, 59, 0.5);
$button-border-radius: 0.2rem;
$keune-lightgrey: #ebebeb;
$product-carousel-item-color: $keune-lightgrey;
$cubic-quint: cubic-bezier(0.86, 0, 0.07, 1);
$mobile-column: 1.5625rem;
$column: 7.2rem;

$headerHeight: 5.5rem;
$announcementBarHeight: 3.5rem;

$contentPaddingTop: calc($headerHeight + $announcementBarHeight);

$reviewStarsHeight: 2rem;
$reviewStarsHeightMobile: 1.6rem;
